.user-pages-head button.primary{
    padding: 9px 41px;
}

.user-pages-head button.primary.primary-outlined{
    background: #fff !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.user-pages-head button.primary.primary-text{
    border: none !important;
    color: var(--font-color) !important;
    background-color: transparent !important;    
}

.user-pages-head button.primary.primary-text-outline{
    border: 1px solid #ececec !important;
    color: var(--font-color) !important;
    background-color: transparent !important;    
}

.user-pages-head button.primary p{
    font-weight: 500 !important;
    font-size: 18px !important;
    text-transform: capitalize;
}


.user-pages-head .Search-users fieldset, .user-pages-head .Search-roles fieldset{
    border: none;
    outline: none;
    box-shadow: none;
}


.user-pages-head .MuiBreadcrumbs-root a{ text-decoration: none !important; }

.Search-roles > div, .Search-users > div{
    padding: 10px 15px 8px !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
}

.Search-roles > div > input, .Search-users > div > input{
    padding: 0px !important;
}