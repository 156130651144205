.cancel-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
    margin: auto;
    width: 358px;
    height: 244px;
    min-width: 358px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    border: none !important;
}
.cancel-modal-content{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 10px;
    /* box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px; */
    border-radius: 10px;
}

.cancel-modal .buttons-wrap button{
    width: 141px !important;
    height: 60px !important;
    font-size: 18px;
    font-weight: 500;
    text-transform: none !important;
    border: 1px solid;
}
.cancel-modal .buttons-wrap button.primary{
    border-color: #F6B119 !important;
    color: #fff !important;
}
.cancel-modal .buttons-wrap button:not(.primary){
    border-color: #bebebe !important;
    color: #333132 !important;
}

.cancel-modal-content #duration::-webkit-outer-spin-button,
.cancel-modal-content #duration::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cancel-modal-content #duration, .cancel-modal-content p{
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #333132;
}

.cancel-modal-content .MuiInputBase-root{ padding: 0px !important; }
.cancel-modal-content #duration{ padding: 10px 10px !important; }

.cancel-modal-content .MuiInputBase-root fieldset{
    /* padding: 0px !important; */
    /* border: none !important; */
    /* box-shadow: none !important; */
}

.cancel-modal-content .MuiInputBase-root .MuiInputAdornment-root.MuiInputAdornment-positionStart,
.cancel-modal-content .MuiInputBase-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd{ display: none; }
/* { margin: 0px !important; } */