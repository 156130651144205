.map-wrap .details-text {
    color: #fff;
    background-color: #f6b119;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.map-wrap > div {
    height: 210px !important;
    margin-bottom: 10px !important;
    border-radius: 0px 0px 10px 10px !important;
}
.map-wrap > .map-wrap-address{
    font-size: 13px;
    color: #333132;
    display: flex;
    align-items: center;
}

.map-wrap > .map-wrap-address > img{
    width: 20px;
    height: 20px;
    margin: 0.8px 7.6px 1.3px 0;
}
