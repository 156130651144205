.select-user-form{

}

.select-user-form .searchable-select{
    text-align: left;
}
.css-1jqq78o-placeholder{ color: #bebebe !important; }

.select-user-form-submit{
    padding: 11px 80px 7px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
}