.assigned-activities .assigned-activities-header button.primary p.MuiTypography-root {
    font-size: 18px !important;
}

/* .searchable-select.assigned-activity-select{ z-index: 9; } */
.searchable-select.assigned-activity-select div[id^='react-select']{ z-index: 9; }
.searchable-select.assigned-activity-select > div{ z-index: 8; }

/* .searchable-select.assigned-activity-select div[id^='react-select'] ul li.Mui-selected,
.searchable-select.assigned-activity-select div[id^='react-select'] ul li.Mui-selected *{
    color: #333132 !important;
    background-color: rgba(25, 118, 210, 0.1) !important;
} */

.searchable-select.assigned-activity-select > div, 
.login-input.assigned-activity-input > div {
    background-color: #fcfcfc !important;
    border-color: #bebebe !important;
}

.searchable-select.assigned-activity-select input,
.login-input.assigned-activity-input input{
    color: #333132 !important;
    padding-left: 0px !important;
}
.searchable-select.assigned-activity-select svg,
.login-input.assigned-activity-input svg{
    color: black !important;
}

.login-input.assigned-activity-input .MuiInputAdornment-root.MuiInputAdornment-positionStart{
    margin: 0px !important;
}