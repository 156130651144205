.activity-form{
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
    margin: 10px;
 
    margin-bottom: 30px;
}
.custom-radio-btn{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    border: 0.5px solid #000;
    margin-top: 4px !important;
    cursor: pointer;
}

.custom-radio-btn-disabled{
    width: 15px;
    height: 15px;
    border-radius: 50%;
   
    display: inline-block;
    
    /* margin-top: 4px !important; */
    cursor: pointer;
}

.custom-radio-btn-disabled {
    color: gray;
}
.active-select{
    background: var(--primary-color);
}
.active-btns{
    color: var(--primary-color) !important;
    border: 0.5px solid var(--primary-color) !important;
}
.activity-img-container{
    width: 140px;
    height: 140px;
    border-radius: var(--lg-radius);
    overflow: hidden;
    text-align: center;
}
.activity-img-container img{
    width: auto;
    height: 100%;
    max-width: 100%;
    margin: auto;
}
.render-options{
    position: absolute;
    height: 300px;
    overflow: auto;
    width: 90%;
    list-style: none;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 10px;
    z-index: 99;
}
.render-item{
    cursor: pointer;
    padding: 5px;
}
.render-item:hover{
    background: #f9f9f9;
}
.icon-style{
    display: block !important;
    margin: auto;
    cursor: pointer;
}
.left-arrow-icon{
    transform: rotate(90deg);
}
.right-arrow-icon{
    transform: rotate(270deg);
}
.price-items-panel{
    border: 1px solid #d9d9d9;
    border-radius: var(--lg-radius);
    height: 250px;
    overflow: auto;
}
.icons-container{
    position: absolute;
    width: 25px;
    left: 50%;
    margin-left: -12.5px;
    height: 140px;
    top: 50%;
    margin-top: -70px;
}
.full-width{
    width: 100%;
}

.payment-label span:nth-of-type(2){
    font-weight: bold !important;
}

/* remove mui number input arrows */
.add-activity-container input[type=number]::-webkit-inner-spin-button, 
.add-activity-container input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.add-activity-container .searchable-select-branches + .MuiChip-root{
    top: -27px !important;
}