.upload-contract-box{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.upload-contract-box img{
    width: 40px;
    height: 40px;
}

.upload-contract-input{
    text-decoration: none;
    color: #f6b119;
    margin-top: 5px;
}

.contract-card{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    width: 145px;
    max-width: 100%;
    margin: auto;
    height: 145px;
    padding: 0.5rem;
    border: 1px solid #f6b119;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
}

.contract-card p{
    text-decoration: none;
    color: #f6b119;
    margin-top: 5px;
    overflow-wrap: anywhere;
}

.complete-profile .react-tel-input .form-control {
    margin-top: 8px !important;
}

/* .complete-profile .react-tel-input .flag-dropdown {
    margin-top: 8px !important;
} */

#react-select-2-listbox{ z-index: 9; }

textarea#main_office_address, textarea#about_us{ padding: 16.5px 14px !important; }

.complete-profile :where(.owner-info-phone-input, .dive-oper-phone-input, .contact-person-phone-input).react-tel-input .flag-dropdown {
    top: 8px !important;
    height: 56px !important;
    background-color: #fff !important;
}
.complete-profile .owner-info-phone-input input,
.complete-profile .dive-oper-phone-input input,
.complete-profile .contact-person-phone-input input{
    border-radius: 10px !important;
    padding-left: 60px !important;
    font-size: 1rem !important;
    height: 56px !important;
}
.complete-profile .owner-info-phone-input .special-label,
.complete-profile .dive-oper-phone-input .special-label,
.complete-profile .contact-person-phone-input .special-label{
    top: -11px !important;
}