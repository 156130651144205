.table-header{
    color: #818181 !important;
    font-weight: bold !important;
}
.activity-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: var(--sm-radius);
    padding: 15px 0;
    line-height: 3;
    cursor: pointer;
}