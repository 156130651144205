
@media screen and (max-width: 992px) {
    .details-panel { flex-direction: column !important; }
    .details-panel > div{ width: 100% !important; }    
}

.applicant-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item > div.MuiStack-root{
    width: 208px;
    height: 149px;
}

.applicant-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item{
    width: auto !important;
    min-width: fit-content;    
    padding: 0px 30px 0px 0px;
    z-index: 9;
}

.applicant-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item > div{
    width: 208px;
    height: 149px;
}

.applicant-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item > div > img{
    width: 100% !important;
    height: 100% !important;
}

.applicant-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item > *{ user-select: none; }


.applicant-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item{
    width: auto !important;
    min-width: fit-content;    
    padding: 0px 40px 0px 0px !important;
    z-index: 9;
}
/* .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item:first-of-type{ padding-left: 0px !important; } */
/* .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item:last-of-type{ padding-right: 0px !important; } */

.applicant-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item > div{
    width: 178px;
}

.applicant-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item > *{ user-select: none; }

.react-tel-input .header-phone{
    color: white !important;
    background-color: #333132 !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    height: 24px;
    padding: 0px;
}