.custom-accordion{ box-shadow: none !important; }
.custom-accordion:not(.Mui-expanded){ margin-bottom: 1.5rem; }
.custom-accordion::before{ display: none !important; }

.custom-accordion .MuiButtonBase-root .MuiAccordionSummary-content{ margin-right: 1rem; }

.custom-accordion .MuiAccordionSummary-content p.MuiTypography-root{
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.91;
    letter-spacing: normal;
    text-align: left;
    color: #272726;
}


.custom-accordion .MuiAccordionDetails-root p.MuiTypography-root{
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #272726;
}