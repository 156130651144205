.attention-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
    margin: auto;
    width: 1025px;
    height: 432px;
    min-width: 65%;    
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: end !important;
    border: none !important;
}

.attention-modal-content{
    display: grid;
    grid-template-columns: 8rem auto;
    grid-template-rows: auto auto;
    grid-template-areas: 'atImg atH1' 'atImg atP';
    align-items: center;
    min-height: 7rem;
    gap: 0px 33.6px;
    padding: 0rem 11rem;
    margin-bottom: 5rem;
}

.attention-modal-content > img {
    grid-area: atImg;
    width: 110.2px;
    height: 105.9px;
    display: block;
    margin: auto;
}
.attention-modal-content > h1{
    grid-area: atH1;
    color: #fa0000; 
    font-weight: bold;
    font-size: 30px;
}
.attention-modal-content > p{
    grid-area: atP;
    padding-right: 6rem;
    color: #333132;
    font-size: 20px;
}

.attention-modal .attention-modal-content > h1, .attention-modal .attention-modal-content > p{ text-align: left; }
.attention-modal .attention-modal-content > h1 > span{ text-transform: capitalize; }
.attention-modal .buttons-wrap{
    margin-bottom: 2rem;
}
.attention-modal .buttons-wrap > button{
    font-size: 18px !important;
    text-transform: capitalize !important;
}
.attention-modal .buttons-wrap > button.primary.r-25px{
    width: 160px;
    color: white !important;
    background-color: #fa0000 !important;
}
.attention-modal .buttons-wrap > button:not(.primary.r-25px){
    width: 150px;
    color: #333132 !important;
}

.MuiModal-backdrop{
    opacity: 0.2 !important;
    background-color: #000 !important;
}