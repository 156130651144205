.password-form-wrap{
    margin: 12rem auto 0rem;
}


.password-form-wrap .password-form-head, .password-form-wrap .password-form{
    display: flex !important;
    flex-direction: column !important;
    margin: auto;
    align-items: center;
}

.password-form-head{
    gap: 2rem;
}

.password-form-head img{
    display: block;
    max-width: 100%;
}


.password-form-head h1{
    font-size: 28px;
    font-weight: bold;
    color: #272726;
    text-align: center;
}

.password-form-wrap input{
    box-shadow: none !important;
}

.password-form-wrap .password-form{
    gap: 1rem;
}

.password-form-wrap .password-form input{
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgb(0 0 0 / 20%);
    display: block;    
}

.password-form-wrap button{
    color: #fff;
    position: static !important;
    text-transform: capitalize;
}

.password-form-wrap .MuiInputBase-fullWidth{
    padding: 0px!important;
}

.password-form-wrap .css-ittuaa-MuiInputAdornment-root{
    margin-right: 0px !important;
}

.password-form-wrap .css-1laqsz7-MuiInputAdornment-root{
    margin-left: 0px !important;
}

.password-form-wrap .MuiOutlinedInput-root{
    border-radius: 10px !important;
    border: none !important;
}