.phone-input-class:focus-visible{
    border: none !important;
    outline: none !important;
}
.phone-input-class::placeholder{ color: #bbbbbb !important; }

.react-tel-input.hide-flag .special-label {
    top: -19px !important;
}
.hide-flag .flag-dropdown {
    display: none !important;
}

.react-tel-input.hide-special-label .special-label {
    display: none !important;
}