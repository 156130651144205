
.user-form {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
    margin: 10px;
    margin-bottom: 30px;
}
.user-form .MuiSelect-select{
    padding-right: 20px !important;
    padding-left: 10px !important;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.user-form svg.MuiSelect-icon{
    right: 4px !important;
    color: var(--primary-color) !important;
}

.user-form .MuiFormLabel-root{
    color: #00000052 !important;
    background-color: #fff;
    font-weight: bold !important;
}

.user-form #name, .user-form #role_id, .user-form #email, .user-form #phone_number, .user-form #password, .user-form #password_confirmation{
    font-size: 16px !important;
    font-weight: bold;
    color: #333132 !important;
    padding-inline: 14px !important;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);        
}
.user-form .password_confirmation .MuiInputBase-root{ padding: 0px !important; }
.user-form .password_confirmation .MuiInputBase-root .MuiInputAdornment-root.MuiInputAdornment-positionStart{ margin: 0px !important; }

.user-form .password_confirmation button.MuiIconButton-root{
    padding-right: 0px !important;
}

.user-form #phone_number::-webkit-outer-spin-button,
.user-form #phone_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-form .MuiOutlinedInput-root{
    border: none !important;
}

.user-form .MuiOutlinedInput-root fieldset{
    border: none;
    outline: none;
    box-shadow: none !important;
}


.user-form .activity-img-container{
    max-width: 100% !important;
}

.user-form .searchable-select-branches + .MuiChip-root{
    top: -27px !important;
}