.wallet-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    /* padding: 15px !important; */
    padding: 25px 50px !important;
    align-items: center;
}

.table-header.wallet-item{
    border: none !important;
    padding: 10px 50px !important;
}

.wallet-item > div{
    font-size: 20px;
    overflow-wrap: anywhere;
    color: #333132;
    font-weight: normal;
}
.table-header.wallet-item > div{
    color: #818181 !important;
    font-weight: 500 !important;
}
.wallet-item-name {
    font-weight: bold !important;
}

.wallet-item > *:not(.wallet-item-email){ text-transform: capitalize; }


