.selesman-item {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 15px 0;
  align-items: center;
}

.selesman-item > div {
  font-size: 20px;
  overflow-wrap: anywhere;
}

div.demo-menu-not-current[id^="demo-positioned-menu"]{
    display: none !important;
}

.selesman-item .salesman-phone{
    color: #000 !important;
    background-color: #fff !important;
    font-weight: 500 !important;
    font-size: 18px !important;
}
