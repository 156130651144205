.review-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    margin: auto;
    padding: 3rem 1rem;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;    
}

.review-modal > img{
    max-width: 100%;
    width: 35%;
    min-width: 322.6px;
    min-height: 59px;
    margin: 0rem auto 3rem;
}

@media screen and (max-width: 500px) {
    .review-modal > img{
        width: 90% !important;
        min-width: 90% !important;
    }
}

.review-modal h1, .review-modal p{
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333132;
}
.review-modal h1{
    font-size: 30px !important;
    line-height: 1.33 !important;
}

.review-modal p.modal-description{
    font-size: 20px !important;
    line-height: 2 !important;
}

.review-modal p.modal-description.modal-rejection{
    color: #e10007 !important;
    font-size: 24px !important;
    font-weight: bold !important;    
    position: relative;
}
.review-modal p.modal-description.modal-rejection::after{
    content: "";
    position: absolute;
    inset: auto calc(50% - 50px) 5px;
    width: 100px;
    height: 3px;
    background-color: #e10007;    

}


.review-modal button{
    font-weight: 500 !important;
    padding: 14px 40px;
    min-width: 99px;
    width: auto;
    height: 60px;
    text-transform: capitalize !important;
}
.review-modal button:not(.primary){
    color: #333132 !important
}

.MuiModal-backdrop{
    opacity: 0.2 !important;
    background-color: #000 !important;
}