/* @import "Arabic.css"; */

.verif-code>div {
  width: 20rem !important;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding-inline-start: 15px;
  margin: auto;
}

.verif-code>div>input {
  width: 33px !important;
  border: none !important;
  border-bottom: 2px solid grey !important;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 0 !important;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.special-date fieldset {
  border-color: gray !important;
  color: black !important;
}

.special-date label {
  border-color: gray !important;
  color: black !important;
}

input::placeholder,
textarea::placeholder,
.MuiFormHelperText-root.Mui-error {
  text-transform: capitalize;
}

.css-1sb723f-MuiFormHelperText-root,
.MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  font-family: Catamaran;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */

.carosal .slick-slide div {
  width: 142px !important;
  height: 142px !important;
}

.cond-carosal .slick-track {
  width: auto !important;
}

.carosal .slick-list {
  width: 750px !important;
}

.react-select__menu {
  background-color: white !important;
}

.react-select__menu-list {
  background-color: white !important;
}



@media screen and (max-width: 899px) {
  .table-header,
  .branch-item,
  .activity-item,
  .table-item ,
  .selesman-item,
  .user-item ,
  .wallet-item {
    justify-content: space-between;
  }
}
