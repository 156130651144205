.success-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    margin: auto;
    padding: 3rem 1rem;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;    
}

.success-modal > img{
    max-width: 100%;
    width: 35%;
    min-width: 322.6px;
    min-height: 59px;
    margin: 0rem auto 3rem;
}

@media screen and (max-width: 500px) {
    .success-modal > img{
        width: 90% !important;
        min-width: 90% !important;
    }
}

.success-modal #modal-modal-title, .success-modal #modal-modal-description{
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #333132;
}

.success-modal #modal-modal-description{
    font-size: 20px !important;
    line-height: 40px !important;
}

.MuiModal-backdrop{
    opacity: 0.2 !important;
    background-color: #000 !important;
}