.details-panel{
    background: #fbfbfb;
    border-radius: var(--lg-radius);
    padding: 20px;
    margin-bottom: 20px;
}
.details-panel h1{
    border-bottom: 2px solid var(--primary-color) !important;
    font-weight: bolder !important;
    height: 30px !important;
}