.side-bar-container{
    background: #333132;
    padding: 30px 0;
}
.navbar-container{
    box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    height: 93px;
}
.side-bar-btn{
    background: #424141 !important;
    border-radius: var(--lg-radius) !important;
    color: #fff !important;
    height: auto !important;
    width: 100% !important;
    justify-content: start !important;
    font-size: 16px !important;
    padding: 9px 10px 9px 0px !important;
    text-decoration: none !important;
}
.side-bar-btn button:hover{ background-color: transparent !important; }
.side-bar-btn button .MuiButton-endIcon {
    /* justify-content: right; */
    /* margin-right: 0px !important; */
    max-height: 24px !important;
    grid-area: g3;
    display: block;
}
.side-bar-btn button .MuiButton-endIcon svg{
    font-size: 1.5rem !important;
}
.side-bar-btn.active{
    background: #f6b119 !important;
}
.sub-link-active{
    text-decoration: none !important;
    margin-inline-start: 10%;
}
.sub-link-active:first-of-type {
    margin-top: 5px !important;
    display: block;
    width: 100%;
}
.sub-link-active.active button{
    background: #eba715 !important;
    border: 1px solid #eba715 !important;
    border-radius: 10px !important;
    width: 90% !important;
}
.sub-link-active button.sub-link p {
    padding-left: 32px !important;
    text-align: left;
}

.side-bar-btn button.button-menu{
    position: relative;
    justify-content: space-between !important;
    padding: 8px 8px !important;
}
.side-bar-btn > button{
    display: grid;
    grid-template-columns: 35px 1fr 24px;
    grid-template-areas: "g1 g2 g3";
}
.side-bar-btn > button span{
    margin: 0px !important;
}
.side-bar-btn > button span.MuiButton-startIcon{
    grid-area: g1;
    margin-right: 10px !important;
}
/* .side-bar-btn button.button-menu p {
    position: absolute !important;
    left: 63px !important;
    right: auto !important;
    top: 9px;
}     */
.side-bar-btn > button p {
    /* position: relative !important;
    inset: 0px !important; */
    text-align: left;
    grid-area: g2;
    padding-left: 10px !important;
}

button.terms-btns p {
    text-align: left;
}
