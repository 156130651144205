.activity-form{
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
    margin: 10px;
  
    margin-bottom: 30px;
}
.custom-radio-btn{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    border: 0.5px solid #000;
    margin-top: 4px !important;
    cursor: pointer;
}
.active-select{
    background: var(--primary-color);
}
.active-btns{
    color: var(--primary-color) !important;
    border: 0.5px solid var(--primary-color) !important;
}
.activity-img-container{
    width: 140px;
    height: 140px;
    border-radius: var(--lg-radius);
    overflow: hidden;
    text-align: center;
}
.activity-img-container img{
    width: auto;
    height: 100%;
    max-width: 100%;
    margin: auto;
}
.render-options{
    position: absolute;
    height: 300px;
    overflow: auto;
    width: 90%;
    list-style: none;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 10px;
    z-index: 99;
}
.render-item{
    cursor: pointer;
    padding: 5px;
}
.render-item:hover{
    background: #f9f9f9;
}
.react-tel-input .form-control{
    height: 100% !important;
}

.react-tel-input .country-list .country{
    padding: 5px 11px 9px 46px !important;
}

.activity-form .primary.addLicenseBtn{
    padding: 10px 15px !important;
}