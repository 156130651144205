
.delete-modal .buttons-wrap button{
    width: 141px !important;
    height: 60px !important;
    font-size: 18px;
    font-weight: 500;
    text-transform: none !important;
    border: 1px solid;
}
.delete-modal .buttons-wrap button.primary{
    border-color: #FA0D00 !important;
    color: #fff !important;
    background-color: #FA0D00 !important;
}
.delete-modal .buttons-wrap button:not(.primary){
    border-color: #bebebe !important;
    color: #333132 !important;
}

