.carousel-certificate
  ul.react-multi-carousel-track
  li.carousel-certificate-item {
  width: auto !important;
  min-width: fit-content;
  padding: 0px 20px;
  z-index: 9;
}
.carousel-certificate
  ul.react-multi-carousel-track
  li.carousel-certificate-item:first-of-type {
  padding-left: 0px !important;
}
.carousel-certificate
  ul.react-multi-carousel-track
  li.carousel-certificate-item:last-of-type {
  padding-right: 0px !important;
}

.carousel-certificate
  ul.react-multi-carousel-track
  li.carousel-certificate-item
  > div.MuiStack-root {
  width: 178px;
}

.carousel-certificate
  ul.react-multi-carousel-track
  li.carousel-certificate-item
  > * {
  user-select: none;
}
