form.form-element {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

#sender_email, #description{
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);        
}
.form-element fieldset{
    border: none !important;
    outline: none;
    box-shadow: none;
}

.form-element .MuiInputBase-fullWidth{
    padding: 0px!important;
}

.form-element .css-ittuaa-MuiInputAdornment-root{
    margin-right: 0px !important;
}

.form-element .css-1laqsz7-MuiInputAdornment-root{
    margin-left: 0px !important;
}

.form-element .MuiOutlinedInput-root{
    border-radius: 10px !important;
    border: none !important;
}

form.form-element > button{
    position: absolute !important;
    bottom: -6rem;
    top: auto;
    left: -2rem;    
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;    
    color: white;
}