.rejection-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    max-width: 1024px;
    margin: auto;    
    padding: 30px 60px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;    
}

.rejection-modal > h1{
    margin: 0rem auto 3rem 0rem !important;
}

.rejection-modal > p{
    padding-left: 1rem;
    font-size: 18px;
    line-height: 1.67;
    font-weight: bold;    
    max-height: 187px;
    overflow: auto;
    text-align: left;
}

.rejection-modal > p.errorValid {
    color: #d32f2f;
    font-size: 1.1rem;
    font-weight: 400 !important;
    line-height: 1.66;
    margin: 3px 14px 0px;
    text-align: left;
    width: 100%;
}

.rejection-modal .rejection-msg .MuiInputBase-root.MuiOutlinedInput-root{ padding: 0px !important; }
.rejection-modal .rejection-msg fieldset.MuiOutlinedInput-notchedOutline{ border: none !important; }
.rejection-modal .rejection-msg textarea{
    padding-left: 0.4rem;
    font-size: 18px !important;
    line-height: 1.67 !important;
}

.rejection-modal > div.buttons-wrap{
    flex-wrap: wrap;
    justify-content: center;
}
.rejection-modal > div.buttons-wrap > button{
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 1.11 !important;
    padding: 1.1rem !important;
    width: 170px !important;
    text-transform: capitalize !important;
}


.MuiModal-backdrop{
    opacity: 0.2 !important;
    background-color: #000 !important;
}