.update-profile .react-tel-input .form-control {
    margin-top: 8px !important;
}

.update-profile .update-profile-img-box{ width: 200px !important; }
.update-profile .update-profile-img-box > .MuiGrid-root.activity-form{
    padding: 24px 30px !important;
}
.update-profile .update-profile-img-box > .MuiGrid-root.activity-form > .MuiGrid-root.MuiGrid-item:nth-of-type(2){
    margin-bottom: 0px !important;
    margin-top: 20px !important;
}

.update-profile .react-tel-input .form-control.phone-input-class:focus {
    border-color: #faa100 !important;
    box-shadow: 0 0 0 1px #faa100 !important;
}

.update-profile .owner-info-phone-input input,
.update-profile .dive-oper-phone-input input,
.update-profile .contact-person-phone-input input{
    border-radius: 10px !important;
    padding-left: 60px !important;
    font-size: 1rem !important;
    height: 56px !important;
}
.update-profile .owner-info-phone-input .special-label,
.update-profile .dive-oper-phone-input .special-label,
.update-profile .contact-person-phone-input .special-label{
    top: -11px !important;
}