.salesmen-reservations-search{
    padding: 0px 0px !important;
    background-color: #fff;
}

.salesmen-reservations-search input{
    padding: 13.1px 0px !important;
    background-color: transparent !important;
}
.salesmen-reservations-search input::placeholder{
    /* color: #BEBEBE !important; */
    font-size: 18px !important;    
    font-weight: 400 !important;    
}

.salesmen-reservations-search fieldset{
    border: 1px solid #ececec !important;
    border-radius: 10px !important;
    background-color: transparent !important;
}



.salesmen-reservations-filters .MuiGrid-root.border-r-10px{
    background-color: #fff !important;
}
.salesmen-reservations-filters .MuiGrid-root.border-r-10px label.Mui-focused{
    z-index: 9;
}
.salesmen-reservations-filters .MuiGrid-root.border-r-10px input, 
.salesmen-reservations-filters .MuiGrid-root.border-r-10px .searchable-select div[class$="placeholder"],
.salesmen-reservations-filters .MuiGrid-root.border-r-10px .searchable-select svg{
    color: #333132 !important;
}
.salesmen-reservations-filters .MuiGrid-root.border-r-10px fieldset ,
.salesmen-reservations-filters .MuiGrid-root.border-r-10px .searchable-select>div{
    border: none !important;
    border-radius: 10px !important;
    background-color: transparent !important;    
    box-shadow: 0px 0px 8px 0px #00000014 !important;
}

span[class$="indicatorSeparator"]{ display: none !important; }
/* .searchable-select div[class$="menu"] ul{ background-color: #fff !important; } */
/* .searchable-select div[class$="menu"]{ z-index: 9 !important; } */
.searchable-select div[id^='react-select']{ background-color: #fff !important; }
.searchable-select div[id^='react-select'] ul{ min-height: 8rem !important; }
.searchable-select div[id$='placeholder'] { min-height: auto !important; }

.salesmen-reservations-header .border-r-10px{
    border-radius: 10px !important;
}
.salesmen-reservations-filters .MuiGrid-root.border-r-10px.searchable-select-branches-grid fieldset,
.salesmen-reservations-filters .MuiGrid-root.border-r-10px.searchable-select-branches-grid .searchable-select>div{
  background-color: hsl(0, 0%, 95%) !important;
}
.salesmen-reservations-filters .MuiGrid-root.border-r-10px.searchable-select-branches-grid .searchable-select svg{
    opacity: 0.5 !important;
}