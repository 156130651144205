.activity-info-panel{
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
}

.reservation_temp .joboffers-search fieldset,
.reservation_temp .reservation-search fieldset {
    height: 58px !important;
}
.reservation_temp .joboffers-search, 
.reservation_temp .reservation-search{
    padding: 0px !important;
}