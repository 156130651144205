.house-activities-list-container .table-items-container .MuiGrid-container .MuiGrid-root:not(:last-of-type),
.house-activities-list-container .table-header .MuiGrid-root{
    font-size: 20px !important;
}
.house-activities-list-container .table-header .MuiGrid-root{
    font-weight: 500 !important;
}
.house-activities-list-container .table-items-container .MuiGrid-container .MuiGrid-root:not(:first-of-type, :nth-of-type(2), :nth-of-type(5)){
    font-weight: 400 !important;
}
.house-activities-list-container .table-items-container .MuiGrid-container .MuiGrid-root:first-of-type{
    color: #ccc;
    font-weight: 700 !important;
}
.house-activities-list-container .MuiGrid-root.MuiGrid-container:nth-of-type(2) .MuiGrid-grid-sm-2.border-r-10px{
    margin-right: 0px !important;
    margin-left: 0px !important;
}