.switch-label>span:nth-of-type(2){
    margin: 0 10px !important;
    color: #bebebe !important;
}



.dive-center-active-switch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color: white !important;
}

.dive-center-active-switch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked{
    color: #f6b119 !important;
}