.salesman-phoneinput{
    margin-bottom: 0px !important;
    padding: 16.5px 14px !important;
    border-radius: 10px !important;
    border: none !important;
    box-shadow: none !important;
}

.salesman-phoneinput-container{
    align-items: unset !important;
    border: 1px solid !important;
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-radius: 10px !important;
}
.salesman-phoneinput-container:hover{ border-color: rgb(0, 0, 0, 0.87) !important; }
.salesman-phoneinput-container:focus,
.salesman-phoneinput-container:focus-visible,
.salesman-phoneinput-container:focus-within{ 
    border-width: 2px !important;
    border-color: #f6b119 !important; 
}

.activity-form .searchable-select-branches + .MuiChip-root,
.activity-form .searchable-select-branches ~ .MuiChip-root{
    top: -27px !important;
}