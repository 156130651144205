.table-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: var(--sm-radius);
    padding: 15px 0;
    line-height: 3;
    cursor: pointer;
}

.table-item > div{
    font-size: 20px;
    text-transform: capitalize;
}

.offers-demo-positioned-menu .MuiMenuItem-root{
    font-size: 16px;
    font-weight: 500;
}