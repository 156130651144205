.primary{
  background: var(--primary-color) !important;
  border-radius: var(--xlg-radius) !important;
  color: #fff !important;
  cursor: pointer !important;
  font-size: 15px !important;
  line-height: 2 !important;
}
.secoundry{
  background: #fff !important;
  border-radius: var(--xlg-radius) !important;
  color: #000 !important;
  cursor: pointer !important;
  font-size: 15px !important;
  line-height: 2 !important;
  border: 1px solid #ececec !important;
}
.day-btn{
  background: #fcfcfc !important;
  border-radius: var(--lg-radius) !important;
  color: #333132 !important;
  cursor: pointer !important;
  font-size: 13px !important;
  line-height: 2 !important;
  border: 1px solid #ececec !important;
  margin: 4px !important;
}
.grey-btn{
  background: #bebebe !important;
  border-radius: 25px !important;
  color: #fff !important;
  cursor: pointer !important;
  font-size: 15px !important;
  line-height: 2 !important;
  border: 1px solid #ececec !important;
  padding: 10px 15px !important;
}
.terms-btns{
  width: 100% !important;
  justify-content: start !important;
  font-size: 14px !important;
  /* padding: 0 25px !important; */
  color: #9c9c9c !important;
  height: 34px;
}
.terms-btns span,
.side-bar-btn span{
  width: 35px !important;
  justify-content: center;
  margin: 0 10px !important;
}
.r-25px{
  border-radius: 25px !important;
}
.sub-link{
  color: #fff !important;
  font-size: 12px !important;
  width: 100% !important;
  justify-content: flex-start !important;
  /* padding-left: 62px !important; */
  line-height: 2 !important;
}

.sub-link p{
  padding-left: 15px;
}
.cancel-btn{
  background: #f9f9f9 !important;
  border: 1px solid #ececec !important;
  color: var(--error-color) !important;
  border-radius: var(--md-radius) !important;
}
.native-btn{
  border-radius: 25px !important;
  cursor: pointer !important;
  font-size: 15px !important;
  line-height: 2 !important;
  border: 1px solid #ddd !important;
}
.radius-30px{
  border-radius: 30px !important;
}
.clear{
  line-height: 3 !important;
  padding: 5px 30px !important;
  border-radius: 30px !important;
  color: #bebebe !important;
}
.branch-details-info{
  height: 20px !important;
  border-radius: 8px !important;
  border: solid 1px #d9d9d9 !important;
  background-color: var(--white) !important;
  margin: 0 10px !important;
  color: #333132 !important;
  padding: 13px 25px !important;
}