.border-r-10px fieldset {
  border-radius: 10px !important;
}
.basic-time-picker .MuiFormControl-root {
  width: 100%;
}
.basic-time-picker svg {
  color: var(--primary-color);
}

.basic-time-picker-startTime input {
  text-transform: uppercase;
}
.basic-time-picker-startTime input::placeholder {
  text-transform: lowercase;
}
