.verification-code > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


.verification-code > div > input {
    border: none !important;
    border-bottom: 2px solid #7f7f7f !important;
    outline: none;
    box-shadow: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
    width: 50px !important;
    height: 50px !important;
}


.countdown-wrap{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #848383;
}

.countdown-wrap span{
    margin-left: 1rem;
}

.vrcode-resend{
    border: 1px solid var(--primary-color);
    border-radius: 1.7rem;
    background: #fff;
    padding: 0.4rem 1rem;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
}