.salesmen-list.searchable-select{
    border-radius: 10px !important;
}

.salesmen-list.searchable-select div[class$="placeholder"],
.salesmen-list.searchable-select svg{
    color: #333132 !important;
}

.salesmen-list.searchable-select>div{
    border: none !important;
    border-radius: 10px !important;
    background-color: transparent !important;    
}
