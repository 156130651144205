.account-data-modal{ padding: 32px 0px !important; }
.account-data-modal > h1{
    color: #202428 !important; 
    margin-bottom: 10px;   
}
.account-data-modal > p{
  font-weight: normal !important;
  color: #7e8085 !important;
}

.account-data-modal > div.MuiStack-root{
    gap: 20px;
}

.account-data-modal button{
    line-height: normal !important;
    text-transform: none !important;
    border-radius: 8px !important;
    background-color: transparent !important;
}
.account-data-modal button:nth-of-type(1){
    border: solid 1px #181918 !important;
    color: #181918 !important;
}
.account-data-modal button:nth-of-type(2){
    border: solid 1px #ff5252 !important;
    color: #ff5252 !important;
}

.account-data-container .react-tel-input .flag-dropdown{
    display: none;
}

.account-data-container .account_phone{
    height: auto !important;
    padding: 0px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}