.activity-form{
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
    margin: 10px;
   
    margin-bottom: 30px;
}
.custom-radio-btn{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    border: 0.5px solid #000;
    margin-top: 4px !important;
    cursor: pointer;
}
.active-select{
    background: var(--primary-color);
}
.active-btns{
    color: var(--primary-color) !important;
    border: 0.5px solid var(--primary-color) !important;
}
.activity-img-container{
    width: 140px;
    height: 140px;
    border-radius: var(--lg-radius);
    overflow: hidden;
    border: 1px solid var(--primary-color);
    text-align: center;
}
.activity-img-container img{
    width: auto;
    height: 100%;
    max-width: 100%;
    margin: auto;
}
.render-options{
    position: absolute;
    height: 300px;
    overflow: auto;
    width: 90%;
    list-style: none;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 10px;
    z-index: 99;
}
.render-item{
    cursor: pointer;
    padding: 5px;
}
.render-item:hover{
    background: #f9f9f9;
}

.user-form-image .activity-form .activity-img-container{ max-width: 100%; }

/* searchable select style */
.searchable-select-lang > div[class$='control']{
    border-radius: 10px !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    padding: 10px 0px !important;
}
.searchable-select-lang div[class$='placeholder'],
.searchable-select-lang div[class$='singleValue']{
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333132 !important;
    opacity: 1 !important;
}
.searchable-select-lang div[class$='indicatorContainer'] svg{
    color: #000 !important;
}

.searchable-select-lang div[id^='react-select']{ 
    z-index: 9;
}