.login-input fieldset {
  border-radius: var(--lg-radius) !important;
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
}
.login-input > div:nth-of-type(1) {
  background: #fff;
}
.login-input input {
  padding: 16.5px 14px !important;
}
.password-icon {
  color: var(--primary-color) !important;
}
.activity-search > div:nth-of-type(1) {
  height: 48px;
  background: #fff;
}
.activity-search fieldset,
.reservation-search fieldset {
  border-radius: var(--lg-radius);
}
.textarea > div:nth-of-type(1) {
  height: 120px;
}
.textarea fieldset {
  height: 120px;
}
.normal-textField fieldset {
  border-radius: var(--lg-radius);
}

.joboffers-search fieldset {
  border-radius: var(--lg-radius);
  height: 54px;
}

.RateFreelancersModel .textarea fieldset {
  border: none !important;
}


input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}