@font-face {
  font-family: "Catamaran";
  src: local("Catamaran"),
    url("./Assets/fonts/Catamaran/Catamaran-Regular.ttf") format("truetype");
}

html{
  /* overflow: hidden; */
}
*,
body {
  margin: 0;
  font-family: 'Catamaran', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* -----colors----- */

  --primary-color: #faa100;
  --secondary-color: #2794a4;
  --lightblue-color: rgba(85, 212, 229, 0.2);
  --active-color: #6cd21f;
  --third-color: #bbbcbc;
  --dark-color: #545454;
  --label-color: #6f6f6f;
  --error-color: #e10000;
  --success-color: #00b906;
  --placeholder-color: rgba(84, 84, 84, 0.5);
  --lightgray-color: #818181;
  --lightgreen-color: #dc4633;
  /* #559169; */
  --home-subhead-color: rgba(33, 33, 33, 0.75);
  --dark-gray: #424242;
  --beige-color: #ececec;
  --home-text-color: #7e8085;
  --thin-color: #e3e3e3;
  --star-color: #f0b70d;
  --font-color: #272726;
  --secound-f-color: #6e7a8a;
  --border-color: #ececec;
  --formik-error: #d32f2f;

  /* -----fonts----- */
  --gggg-gg:'{t(DoctorProfile.from)}';
  --tiny-font: 10px;
  --mini-font: 12px;
  --small-font: 14px;
  --text-font: 16px;
  --normal-font: 18px;
  --medium-font: 20px;
  --large-font: 22px;
  --xlarge-font: 24px;
  --subtitle-font: 28px;
  --title-font: 32px;
  --header-font: 34px;
  --super-font: 48px;
  --home-header-font: 38px;

  /* -------filters------- */
  --filter-primary: invert(53%) sepia(54%) saturate(7180%) hue-rotate(342deg)
    brightness(89%) contrast(92%);
  --filter-secondary: invert(53%) sepia(59%) saturate(460%) hue-rotate(347deg)
    brightness(104%) contrast(111%);

  /* ----------radius--------- */

  --xs-radius: 3px;
  --sm-radius: 5px;
  --md-radius: 7px;
  --lg-radius: 10px;
  --xlg-radius: 20px;
  --v-xlg-radius: 45px;
  --round-radius: 50%;

  
}

.mb-20px {
  margin-bottom: 20px !important;
}
.mb-30px {
  margin-bottom: 30px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-30px {
  margin-top: 30px !important;
}
.mt-10px {
  margin-top: 10px !important;
}
.pb-pt-20px {
  padding: 20px 0 !important;
}
.tab-panel{
  width: 100%;
}
.tab-panel>div:nth-of-type(1){
  padding: 0 !important;
}
.scrollbar-container>div:nth-of-type(3)>div{
  background-color: grey !important;
}
.check-icon{
  color: var(--primary-color) !important;
  font-size: 20px !important;
  margin: 0 10px !important;
}

.wrong-icon {
  color: #df2525 !important;
  font-size: 20px !important;
  margin: 0 10px !important;
}
.places-search{
  position: absolute;
  z-index: 1;
  width: 70%;
  left: 50%;
  margin-left: -35%;
  margin-top: 20px;
  height: 45px;
  border-radius: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  outline: 1px solid var(--primary-color);
  border: 1px solid var(--primary-color);
}