.branch-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: var(--sm-radius);
    padding: 15px 0;
    align-items: center;
}

.branch-item > div{
    font-size: 20px;
    overflow-wrap: anywhere;
}
.branch-item-no, .branch-item-name {
    font-weight: bold;
}

.branch-item-no {
    text-align: center;
    color: #ccc;
}

.branch-item-reject-btn{
    background: transparent !important; 
    text-decoration: underLine !important;
    border: none !important;
    color: #272726; 
    cursor: pointer;
    font-size: 16px;
    line-height: 1.88;
    text-align: left;
}

.branch-item > *:not(.branch-item-email){ text-transform: capitalize; }


